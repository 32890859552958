import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import Button from "src/components/Button"
import { rem } from "src/utils/styling"
import "./comparison-page.scss"

import slide_1_1 from "src/images/comparison/slide_1_1.svg"
import slide_1_2 from "src/images/comparison/slide_1_2.svg"
import slide_1_3 from "src/images/comparison/slide_1_3.svg"
import slide_2_1 from "src/images/comparison/slide_2_1.svg"
import slide_2_2 from "src/images/comparison/slide_2_2.svg"
import slide_2_3 from "src/images/comparison/slide_2_3.svg"

import { Carousel } from "src/components/Carousel"
import { HiChevronRight } from "react-icons/hi"
import { ReactTypeformEmbed } from "react-typeform-embed"

const TextColumn = styled.div`
  text-align: center;
  padding: ${rem(20)}rem;
  width: ${rem(380)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(750)}rem;
    text-align: left;
  }
`

const CodeContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  flex-direction: column;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    margin-top: ${rem(50)}rem;
  }
`

const CodeContainerMiddle = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  flex-direction: column-reverse;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    margin-top: ${rem(50)}rem;
  }
`

const CodeTextBlock = styled.div`
  display: inline-block;
`

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
`
const CodeImageBlock = styled.img`
  max-width: ${rem(250)}rem;
  width: ${rem(250)}rem;
  margin: 2rem auto;
  @media screen and (min-width: 1100px) {
    max-width: ${rem(300)}rem;
    width: ${rem(300)}rem;
    margin: 0;
  }
`

function ComparisonPage() {
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }

  return (
    <div className="comparison-container">
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        style={{ top: 100 }}
        url="https://jfox813621.typeform.com/to/ygspaPkA"
        ref={typeformRef}
      />
      <div className="home-page-background">
        <div className="container has-text-centered is-centered">
          <div className="columns mb-6">
            <div className="column">
              <p className="is-size-3 has-text-weight-semibold mt-6 mb-5">
                Code Only
              </p>
              <p className="has-text-secondary mt-5 mb-5 sub-heading">
                Walkthrough
              </p>
              <p>Work with our specialist developers at an affordable price.</p>
            </div>
          </div>

          <Carousel>
            <div>
              <CodeContainer>
                <CodeImageBlock src={slide_1_1} alt="Out-task Stories" />
                <CodeTextBlock style={{ width: "30%" }}>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      Write your own{" "}
                      <span className="has-text-primary">user stories</span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Out-task Stories
                      </p>
                      <p>
                        Our customer success analysts enable you to write clear
                        and objective user stories and acceptance criteria to
                        minimize communication friction and optimize velocity.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
              </CodeContainer>
            </div>
            <div>
              <CodeContainer>
                <CodeImageBlock
                  src={slide_1_2}
                  alt="Out-task Stories"
                  style={{ marginTop: "5rem" }}
                />
                <CodeTextBlock style={{ width: "30%" }}>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      Select the bid that{" "}
                      <span className="has-text-primary">works for you</span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Bidding
                      </p>
                      <p className="mb-2">
                        We categorize user stories based on complexity. Talent
                        best suited for your story development is suggested,
                      </p>
                      <p>
                        You’re free to go back and forth with developers to
                        select the bid that works best for you.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
              </CodeContainer>
            </div>
            <div>
              <CodeContainer>
                <CodeImageBlock src={slide_1_3} alt="Out-task Stories" />
                <CodeTextBlock style={{ width: "30%" }}>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      Pay when success criteria{" "}
                      <span className="has-text-primary">are met</span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Payment
                      </p>
                      <p className="mb-2">
                        Release payment once development is completed per your
                        success criteria.
                      </p>
                      <p>
                        We provide you with the necessary legal and payments
                        compliance.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
              </CodeContainer>
            </div>
          </Carousel>
        </div>
        <div className="container has-text-centered is-centered">
          <div className="columns mb-6">
            <div className="column" id="fullservice">
              <p className="is-size-3 has-text-weight-semibold mt-6 mb-5">
                Full Service
              </p>
              <p className="has-text-secondary mt-5 mb-5 sub-heading">
                Walkthrough
              </p>
              <p>Work hassle-free with a high-quality team.</p>
            </div>
          </div>
          <Carousel>
            <div>
              <CodeContainerMiddle>
                <CodeTextBlock>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      We manage
                      <span className="has-text-primary"> the details</span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Full Service Team
                      </p>
                      <p>
                        Our customer-obsessed business analysts work closely
                        with you to understand your functional requirements and
                        convert them into actionable user-stories and acceptance
                        criteria.
                      </p>
                      <p className="mt-2">
                        Your primary responsibility is to think about your
                        requirements. Our delivery managers, business analysts,
                        and solutions architects manage everything else.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
                <CodeImageBlock src={slide_2_1} alt="Out-task Stories" />
              </CodeContainerMiddle>
            </div>
            <div>
              <CodeContainerMiddle>
                <CodeTextBlock>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      We ensure{" "}
                      <span className="has-text-primary">code quality</span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Dedicated Architects
                      </p>
                      <p>
                        Sit back while our solution architects and customer
                        success analysts work with developers behind the scene
                        to convert your requirements to clean code.
                      </p>
                      <p className="mt-2">
                        Our entire methodology is designed to deliver quality
                        code. Our developers are ranked and compensated based on
                        performance, not by price.{" "}
                      </p>
                      <p className="mt-2">
                        Our master solution architects review every pull
                        request.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
                <CodeImageBlock src={slide_2_2} alt="Bidding" />
              </CodeContainerMiddle>
            </div>
            <div>
              <CodeContainerMiddle>
                <CodeTextBlock>
                  <TextColumn>
                    <p className="is-size-6 has-text-weight-semibold has-text-left">
                      We give you
                      <span className="has-text-primary">
                        {" "}
                        end to end transparency
                      </span>
                    </p>
                    <hr className="green-bar" />
                    <div className="block-highlight">
                      <p className="is-size-4 has-text-weight-bold mb-5">
                        Quality & Transparency
                      </p>
                      <p className="mt-2">
                        You have end to end visibility into our entire process
                        from user story development through to user acceptance
                        testing. Watch as your project comes alive from
                        requirements to code to deployment.
                      </p>
                      <p className="mt-2">
                        Payments are released only once user acceptance testing
                        is successfully completed.
                      </p>
                    </div>
                  </TextColumn>
                </CodeTextBlock>
                <CodeImageBlock src={slide_2_3} alt="Payment" />
              </CodeContainerMiddle>
            </div>
          </Carousel>
        </div>
        <br />
        <hr className="grey-bar" />
        <br />
        <div className="container has-text-centered is-centered">
          <p className="mt-6 has-text-weight-bold footer-note">
            Leverage feature requests to jumpstart your development team and{" "}
            <span className="has-text-warning">
              build powerful digital products.
            </span>
          </p>
          <br />
          <br />
          <div
            onClick={onClickTypeform}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <HeaderButton type="solid">
              Request Code
              <HiChevronRight size={22} />
            </HeaderButton>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  )
}

export default ComparisonPage

import React from "react"
import styled, { ThemeProvider } from "styled-components"
import ComparisonPage from "src/components/comparison-page/comparison-page"
import {
  Banner,
  BannerContent,
  BannerContentFullWidth,
  BannerText,
} from "src/components/Banner"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"
import { PageTitle } from "src/components/Typography"

import left_arrow from "src/images/LeftArrow.svg"
import right_arrow from "src/images/RightArrow.svg"

import { ClientHeader } from "src/components/Header"
import Container from "src/components/Container"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import { LightFooter } from "src/components/Footer"

const LeftArrow = styled.img`
  width: ${rem(35)}rem;
  vertical-align: middle;
  margin-right: ${rem(5)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(90)}rem;
    margin-right: ${rem(20)}rem;
  }
`

const RightArrow = styled.img`
  width: ${rem(20)}rem;
  vertical-align: middle;
  margin-left: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(50)}rem;
    margin-left: ${rem(20)}rem;
  }
`

const OverviewPage = ({ location }) => (
  <ThemeProvider theme={darkTheme}>
    <Banner>
      <ClientHeader />
      <Container>
        <SEO
          title="Offering comparison - Code only | Full service"
          description="The Archimydes platform works in two ways - Full service &amp; Code only. Choose whichever model works best for you and your team."
          location={location}
        />
        <BannerContent className="has-text-centered">
          <BannerContentFullWidth>
            <PageTitle>
              <LeftArrow
                src={left_arrow}
                alt="Out-task Stories"
                className="arrow-image-left"
              />
              Engage our community <em>in two ways</em>
              <RightArrow
                src={right_arrow}
                alt="Out-task Stories"
                className="arrow-image-right"
              />
            </PageTitle>
            <BannerText>
              Choose whichever model works best for you and your team.
            </BannerText>
          </BannerContentFullWidth>
        </BannerContent>
      </Container>
    </Banner>
    <div className="">
      <ComparisonPage />
      <LightFooter />
    </div>
  </ThemeProvider>
)

export default OverviewPage
